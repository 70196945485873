<template>
  <div>
    <!-- Start: Statistics -->
    <v-row v-if="supplier.id" class="mb-5">
      <v-col
        v-for="(item, index) in supplierStatistics"
        :key="index"
        class="py-0"
        cols="4"
      >
        <v-card :class="item.color" class="white" flat>
          <v-card-title class="font-weight-bold justify-center pa-3 pb-1">
            {{ item.text }}
          </v-card-title>
          <v-card-text
            :class="item.textColor"
            class="text-h6 font-weight-bold text-center px-3 pb-3"
          >
            <template v-if="supplier[item.field]"
              >{{ supplier[item.field] | formatCurrency }}
            </template>
            <template v-else>{{ 0 | formatCurrency }}</template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- End: Statistics -->
    <!-- Start: Form -->
    <v-card flat>
      <v-form ref="form" v-model="formValid" lazy-validation>
        <!-- Start: Form -->
        <v-row class="px-5 pt-5">
          <!-- Start: Name -->
          <v-col class="py-0" md="9" sm="12">
            <div class="font-weight-bold mb-2">Tên nhà cung cấp</div>
            <v-text-field
              v-model="supplier.name"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập tên nhà cung cấp"
              single-line
              solo
            ></v-text-field>
          </v-col>
          <!-- End: Name -->
          <!-- Start: Abbreviations -->
          <v-col class="py-0" md="3" sm="12">
            <div class="font-weight-bold mb-2">Tên viết tắt</div>
            <v-text-field
              v-model="supplier.acronym"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập tên viết tắt"
              single-line
              solo
            ></v-text-field>
          </v-col>
          <!-- End: Abbreviations -->
          <!-- Start: Categories -->
          <v-col class="py-0" md="9" sm="12">
            <div class="font-weight-bold mb-2">Loại nhà cung cấp</div>
            <v-select
              v-model="supplier.sup_cates"
              :items="allSupplierCategories"
              :menu-props="{ nudgeTop: '-40px' }"
              class="text-body-1"
              deletable-chips
              dense
              flat
              hide-selected
              item-text="name"
              item-value="id"
              multiple
              no-data-text="Không có dữ liệu"
              outlined
              placeholder="Chọn một hoặc nhiều loại"
              return-object
              single-line
              small-chips
              solo
            ></v-select>
          </v-col>
          <!-- End: Categories -->
          <!-- Start: Abbreviations -->
          <v-col class="py-0" md="3" sm="12">
            <div class="font-weight-bold mb-2">Hạn mức công nợ</div>
            <tp-input-price
              v-model="supplier.max_account_balance"
              custom-class="text-body-1"
              dense
              flat
              outlined
              placeholder="Giá trị"
              single-line
              solo
            ></tp-input-price>
          </v-col>
          <!-- End: Abbreviations -->
          <!-- Start: Phone -->
          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Số điện thoại</div>
            <v-text-field
              v-model="supplier.phone"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập số điện thoại"
              single-line
            ></v-text-field>
          </v-col>
          <!-- End: Phone -->
          <!-- Start: Email -->
          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Email</div>
            <v-text-field
              v-model="supplier.email"
              :rules="[rules.emailFormat]"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập email"
              single-line
            ></v-text-field>
          </v-col>
          <!-- Start: Email -->
          <!-- Start: Zone -->
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Khu vực</div>
            <v-radio-group v-model="supplier.zone" class="mt-0" dense>
              <div class="d-flex flex-row align-center mb-3">
                <v-radio :value="2" class="mb-0" label="Trong nước"></v-radio>
                <v-select
                  v-model="supplier.tinh_thanhpho"
                  :items="cities"
                  :readonly="supplier.zone === 1"
                  class="text-body-1 ml-4"
                  clearable
                  dense
                  hide-details
                  hide-selected
                  item-text="name"
                  item-value="name"
                  label="Outlined"
                  outlined
                  placeholder="Chọn tỉnh/thành phố"
                  single-line
                ></v-select>
              </div>
              <v-radio :value="1" label="Nước ngoài"></v-radio>
            </v-radio-group>
          </v-col>
          <!-- End: Zone -->
          <!-- Start: Address -->
          <v-col class="py-0 mt-2" cols="12">
            <div class="font-weight-bold mb-2">Địa chỉ</div>
            <v-text-field
              v-model="supplier.address"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập địa chỉ"
              single-line
              solo
            ></v-text-field>
          </v-col>
          <!-- End: Address -->
          <!-- Start: Note -->
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Ghi chú</div>
            <v-textarea
              v-model="supplier.note"
              background-color="white"
              class="text-body-1"
              dense
              filled
              outlined
              placeholder="Nhập ghi chú"
              required
              rows="2"
              single-line
            ></v-textarea>
          </v-col>
          <!-- End: Note -->
        </v-row>
        <!-- Start: Form -->
      </v-form>
    </v-card>
    <!-- End: Form -->
    <v-btn
      v-if="supplier.id"
      :disabled="!formValid"
      :loading="supplierStatusRequest.value === 'loading-updateSupplier'"
      class="rounded-lg mt-5 mr-2"
      color="primary"
      depressed
      @click="
        supplierStatusRequest.value === 'loading-updateSupplier'
          ? null
          : updateSupplier()
      "
    >
      Lưu
    </v-btn>
    <v-btn
      v-else
      :disabled="!formValid"
      :loading="supplierStatusRequest.value === 'loading-createSupplier'"
      class="rounded-lg mt-5 mr-2"
      color="primary"
      depressed
      @click="
        supplierStatusRequest.value === 'loading-createSupplier'
          ? null
          : createSupplier()
      "
    >
      Thêm
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formDataAvatar: null,
      formValid: true,
      genderList: ["Nam", "Nữ", "Khác"],
      rules: {
        emailFormat: value => {
          if (value === "" || this.regexEmail(value)) return true;
          else return "Email không đúng định dạng.";
        },
        phoneRequired: value => !!value || "Bạn chưa nhập số điện thoại.",
        phoneFormat: value => {
          if (this.regexPhone(value)) return true;
          else return "Số điện thoại không đúng.";
        }
      },
      supplierStatistics: [
        {
          field: "tong_mua",
          text: "Tổng mua",
          color: "green--text lighten-3",
          textColor: "green--text"
        },
        {
          field: "tong_tra",
          text: "Tổng trả",
          color: "deep-purple--text lighten-3",
          textColor: "deep-purple--text"
        },
        {
          field: "cong_no",
          text: "Công nợ",
          color: "orange--text lighten-4",
          textColor: "orange--text"
        }
      ]
    };
  },
  computed: {
    allSupplierCategories() {
      return this.$store.getters["SUPPLIER/allSupplierCategories"];
    },
    cities() {
      return this.$store.getters["LOCAL_ADMINISTRATION/cities"];
    },
    supplier() {
      return this.$store.getters["SUPPLIER/supplier"];
    },
    supplierStatusRequest() {
      return this.$store.getters["SUPPLIER/statusRequest"];
    },
    uploadedAvatar() {
      return this.$store.getters["SUPPLIER/uploadedAvatar"];
    }
  },
  async created() {
    if (this.cities.length === 0) {
      await this.$store.dispatch("LOCAL_ADMINISTRATION/getCities");
    }
    await this.$store.dispatch("SUPPLIER/getAllSupplierCategories");
  },
  methods: {
    async createSupplier() {
      await this.validate();

      if (this.formValid) {
        const routeQuery = this.$route.query;

        // Check avatar
        if (this.supplier.avatar) {
          await this.uploadAvatar();
        }

        const objectSender = JSON.parse(JSON.stringify(this.supplier));

        objectSender.sup_cates = objectSender.sup_cates.map(item => item.id);

        // Request create
        await this.$store.dispatch("SUPPLIER/createSupplier", {
          data: objectSender,
          routeQuery: {
            filters: {
              fromBuy: routeQuery.buy_from
                ? parseFloat(routeQuery.buy_from)
                : null,
              toBuy: routeQuery.buy_to ? parseFloat(routeQuery.buy_to) : null,
              fromReturn: routeQuery.return_from
                ? parseFloat(routeQuery.return_from)
                : null,
              toReturn: routeQuery.return_from
                ? parseFloat(routeQuery.return_from)
                : null,
              fromBalance: routeQuery.debt_from
                ? parseFloat(routeQuery.debt_from)
                : null,
              toBalance: routeQuery.debt_to
                ? parseFloat(routeQuery.debt_to)
                : null,
              fromDate: routeQuery.date_from,
              toDate: routeQuery.date_to
            },
            search: routeQuery.search || null,
            per_page: parseInt(routeQuery.limit),
            cur_page: parseInt(routeQuery.page)
          }
        });
        // Alert
        if (this.supplierStatusRequest.value === "success-createSupplier") {
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: "Thêm mới nhà cung cấp thành công"
            }
          });
        }
      }
    },

    regexEmail(email) {
      const isEmail = new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
      );

      return isEmail.test(email);
    },

    regexPhone(phone) {
      const isVnMobilePhone = /^(0|\+84)(\s|\.)?((3[2-9])|(5[2689])|(7[06-9])|(8[1-9])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;
      return isVnMobilePhone.test(phone);
    },

    async updateSupplier() {
      await this.validate();

      if (this.formValid) {
        const routeQuery = this.$route.query;

        // Check avatar change
        if (
          this.supplier.avatar &&
          this.supplier.avatar.slice(0, 7) !== "backend"
        ) {
          await this.uploadAvatar();
        }

        const objectSender = JSON.parse(JSON.stringify(this.supplier));

        objectSender.sup_cates = objectSender.sup_cates.map(item => item.id);

        // Request update
        await this.$store.dispatch("SUPPLIER/updateSupplier", {
          data: objectSender,
          routeQuery: {
            filters: {
              fromBuy: routeQuery.buy_from
                ? parseFloat(routeQuery.buy_from)
                : null,
              toBuy: routeQuery.buy_to ? parseFloat(routeQuery.buy_to) : null,
              fromReturn: routeQuery.return_from
                ? parseFloat(routeQuery.return_from)
                : null,
              toReturn: routeQuery.return_from
                ? parseFloat(routeQuery.return_from)
                : null,
              fromBalance: routeQuery.debt_from
                ? parseFloat(routeQuery.debt_from)
                : null,
              toBalance: routeQuery.debt_to
                ? parseFloat(routeQuery.debt_to)
                : null,
              fromDate: routeQuery.date_from,
              toDate: routeQuery.date_to
            },
            search: routeQuery.search || null,
            per_page: parseInt(routeQuery.limit),
            cur_page: parseInt(routeQuery.page)
          }
        });
        // Alert
        if (this.supplierStatusRequest.value === "success-updateSupplier") {
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: "Đã lưu cập nhật"
            }
          });
        }
      }
    },

    async uploadAvatar() {
      await this.$store.dispatch("SUPPLIER/uploadAvatar", this.formDataAvatar);
      // Set avatar
      if (this.supplierStatusRequest.value === "success-uploadAvatar") {
        // Set uploaded avatar
        this.supplier.avatar = this.uploadedAvatar;
      }
    },

    validate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.item-info {
  font-size: 1rem;

  &--value {
    font-size: 1.125rem;
    font-weight: 500;
  }
}
</style>
