<template>
  <div class="">
    <div class="d_flex align_center justify-end mb-4">
      <tp-btn-overflow
        btn-class="rounded-lg white text-body-1 py-1 px-3 mr-4"
        dense
        :items="filterTypeList"
        selected-value="all"
        @change="filterTransactionByType($event)"
      ></tp-btn-overflow>
      <v-col class="pa-0" sm="auto" style="width: 248px">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              class="rounded-lg text-body-1 grey--text text--darken-4"
              background-color="white"
              dense
              flat
              hide-details
              prepend-inner-icon="mdi-calendar"
              readonly
              single-line
              solo-inverted
              v-bind="attrs"
              v-on="on"
              width="200px"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            color="primary"
            no-title
            range
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Hủy
            </v-btn>
            <v-btn text color="primary" @click="filterTransactionByDates()">
              Xong
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </div>
    <v-card flat>
      <v-data-table
        class="datatable px-3 py-2"
        calculate-widths
        disable-pagination
        no-data-text="Không có giao dịch nào"
        no-results-text="Không tìm thấy kết quả phù hợp"
        :headers="headers"
        hide-default-footer
        :loading="
          supplierStatusRequest === 'loading-getSupplierTransactionHistory'
        "
        loading-text="Đang tải dữ liệu"
        :items="supplierTransactionHistory"
        item-key="id"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filterTypeList: [
        { id: "all", text: "Tất cả" },
        { id: 1, text: "Phiếu nhập" },
        { id: 2, text: "Phiếu trả NCC" }
      ],
      headers: [
        {
          text: "Giao dịch",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Loại",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Thời gian",
          align: "start",
          sortable: false,
          value: "atTime"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "SKU"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ],
      dates: [],
      menu: false,
      modal: false,
      menu2: false,
      selectedFilterType: 0
    };
  },
  computed: {
    dateRangeText() {
      if (this.dates.length === 0) return "dd/mm/yyyy - dd/mm/yyyy";

      const fromDate = new Date(this.dates[0]),
        fromDateDD = fromDate
          .getDate()
          .toString()
          .padStart(2, "0"),
        fromDateMM = (fromDate.getMonth() + 1).toString().padStart(2, "0"),
        fromDateYYYY = fromDate.getFullYear(),
        toDate = new Date(this.dates[1]),
        toDateDD = toDate
          .getDate()
          .toString()
          .padStart(2, "0"),
        toDateMM = (toDate.getMonth() + 1).toString().padStart(2, "0"),
        toDateYYYY = toDate.getFullYear();

      return `${fromDateDD}/${fromDateMM}/${fromDateYYYY} - ${toDateDD}/${toDateMM}/${toDateYYYY}`;
    },
    supplier() {
      return this.$store.getters["SUPPLIER/supplier"];
    },
    supplierStatusRequest() {
      return this.$store.getters["SUPPLIER/statusRequest"];
    },
    supplierTransactionHistory() {
      return this.$store.getters["SUPPLIER/supplierTransactionHistory"];
    }
  },
  methods: {
    async filterTransactionByType(val) {
      this.selectedFilterType = val;
      await this.$store.dispatch("SUPPLIER/getSupplierTransactionHistory", {
        id: this.supplier.id,
        filters: {
          type:
            this.selectedFilterType === 0
              ? null
              : this.filterTypeList[this.selectedFilterType].id,
          from_date: this.dates && this.dates[0] ? this.dates[0] : null,
          to_date: this.dates && this.dates[1] ? this.dates[1] : null
        }
      });
    },

    async filterTransactionByDates() {
      this.$refs.menu.save(this.dates);
      await this.$store.dispatch("SUPPLIER/getSupplierTransactionHistory", {
        id: this.supplier.id,
        filters: {
          type:
            this.selectedFilterType === 0
              ? null
              : this.filterTypeList[this.selectedFilterType].id,
          from_date: this.dates && this.dates[0] ? this.dates[0] : null,
          to_date: this.dates && this.dates[1] ? this.dates[1] : null
        }
      });
    }
  }
};
</script>
