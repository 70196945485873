<template>
  <tp-modal name="modal-supplier" width="70%" max-width="960px">
    <v-card>
      <v-toolbar
        class="px-5 pb-0 align-start"
        height="56x"
        extension-height="40px"
      >
        <v-toolbar-title class="font-weight-bold">
          {{
            supplier.id
              ? `Nhà cung cấp ${supplier.code}`
              : "Thêm mới nhà cung cấp"
          }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="supplier.id"
          icon
          color="red accent-1"
          @click="deleteSupplier()"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="activeTab" height="40px">
            <template v-if="!supplier.id">
              <v-tab
                class="font-weight-bold"
                v-for="item in tabItems.slice(0, 1)"
                :key="item.id"
              >
                {{ item.text }}
              </v-tab>
            </template>
            <template v-else>
              <v-tab
                class="font-weight-bold"
                v-for="item in tabItems"
                :key="item.id"
              >
                {{ item.text }}
              </v-tab>
            </template>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items class="modal-body-scroll" v-model="activeTab">
        <v-tab-item class="tab-item grey lighten-3 pa-5">
          <tab-info />
        </v-tab-item>

        <template v-if="supplier.id">
          <v-tab-item class="tab-item grey lighten-3 pa-5">
            <tab-bank-account />
          </v-tab-item>

          <v-tab-item class="tab-item grey lighten-3 pa-5">
            <form-object-default-think-account
              :object-id="supplier.id"
              :object-type="BANK_ACCOUNT_OBJECT_TYPE.SUPPLIER"
              @onRedirectToSystemBankAccountsPage="closeModal"
            />
          </v-tab-item>

          <v-tab-item class="tab-item grey lighten-3 pa-5">
            <tab-transaction-history />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-card>
  </tp-modal>
</template>

<script>
import FormObjectDefaultThinkAccount from "@/core/components/form/FormObjectDefaultThinkAccount";
import TabBankAccount from "@/core/components/modals/ModalSupplier/components/TabBankAccount";
import TabInfo from "./components/TabInfo";
import TabTransactionHistory from "./components/TabTransactionHistory";

import { BANK_ACCOUNT_OBJECT_TYPE } from "@/core/constant";

export default {
  components: {
    FormObjectDefaultThinkAccount,
    TabBankAccount,
    TabInfo,
    TabTransactionHistory
  },

  data() {
    return {
      activeTab: null,
      BANK_ACCOUNT_OBJECT_TYPE: BANK_ACCOUNT_OBJECT_TYPE,
      tabItems: [
        { id: "info", text: "Thông tin" },
        { id: "bank-account", text: "Tài khoản giao dịch" },
        { id: "think-bank-account", text: "Tài khoản Think" },
        { id: "transaction-history", text: "Lịch sử giao dịch" }
      ]
    };
  },

  computed: {
    supplier() {
      return this.$store.getters["SUPPLIER/supplier"];
    },
    supplierStatusRequest() {
      return this.$store.getters["SUPPLIER/statusRequest"];
    },
    supplierTransactionHistory() {
      return this.$store.getters["SUPPLIER/supplierTransactionHistory"];
    }
  },

  watch: {
    activeTab(val) {
      if (val === 1 && this.supplierTransactionHistory.length === 0) {
        this.getSupplierTransactionHistory();
      }
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-supplier"
      });
      this.activeTab = null;
      this.editAddressItemIndex = null;
      this.isEditAddressMode = false;
    },

    deleteSupplier() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa nhà cung cấp <strong>"${this.supplier.code}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("SUPPLIER/deleteSupplier", {
                data: this.supplier.id,
                routeQuery: {
                  filters: {
                    fromBuy: routeQuery.buy_from
                      ? parseFloat(routeQuery.buy_from)
                      : null,
                    toBuy: routeQuery.buy_to
                      ? parseFloat(routeQuery.buy_to)
                      : null,
                    fromReturn: routeQuery.return_from
                      ? parseFloat(routeQuery.return_from)
                      : null,
                    toReturn: routeQuery.return_from
                      ? parseFloat(routeQuery.return_from)
                      : null,
                    fromBalance: routeQuery.debt_from
                      ? parseFloat(routeQuery.debt_from)
                      : null,
                    toBalance: routeQuery.debt_to
                      ? parseFloat(routeQuery.debt_to)
                      : null,
                    fromDate: routeQuery.date_from,
                    toDate: routeQuery.date_to
                  },
                  search: routeQuery.search || null,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });
              if (
                this.supplierStatusRequest.value === "success-deleteSupplier"
              ) {
                this.$modal.hide({ name: "modal-supplier" });
                await this.$store.dispatch("SUPPLIER/resetSupplier");
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    async getSupplierTransactionHistory() {
      await this.$store.dispatch("SUPPLIER/getSupplierTransactionHistory", {
        id: this.supplier.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
</style>
