<template>
  <v-card class="px-5 py-4" flat id="formObjectDefaultThinkAccount">
    <div class="font-weight-bold mb-3">
      Tài khoản Think giao dịch với đối tác
    </div>

    <v-radio-group
      v-model="selectedBankAccount"
      class="mt-0"
      dense
      hide-details
    >
      <template v-for="item in companyBankAccounts">
        <v-radio :key="item.id" :value="item.id">
          <template #label>
            <div>
              <div>
                <span>Ngân hàng:</span>
                <span class="font-weight-bold">
                  {{ item.bank_code }} ({{ item.bank_name }})
                </span>
              </div>

              <div class="mt-1">
                <span>Chủ tài khoản:</span>
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
              </div>

              <div class="mt-1">
                <span>Số tài khoản:</span>
                <span class="font-weight-bold">
                  {{ item.number }}
                </span>
                <v-btn
                  color="primary"
                  icon
                  small
                  @click="
                    copyToClipboard(
                      item.number,
                      'formObjectDefaultThinkAccount'
                    )
                  "
                >
                  <v-icon size="18px">mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-radio>

        <v-divider class="mb-3" :key="`dv-${item.id}`"></v-divider>
      </template>
    </v-radio-group>

    <router-link
      :to="{ name: 'system_bank-accounts' }"
      @click.native="$emit('onRedirectToSystemBankAccountsPage')"
    >
      <div class="tp-btn--text text-body-1">
        <v-icon color="primary" size="18px">mdi-arrow-top-right</v-icon>
        Tạo mới/sửa tài khoản giao dịch
      </div>
    </router-link>

    <v-btn
      class="mt-5"
      color="primary"
      depressed
      @click="setDefaultThinkBankAccountForObject"
      >Xác nhận</v-btn
    >
  </v-card>
</template>

<script>
export default {
  props: {
    objectId: {
      type: Number,
      required: true
    },
    objectType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      selectedBankAccount: null
    };
  },

  computed: {
    bankAccountStatusRequest() {
      return this.$store.getters["BANK_ACCOUNT/statusRequest"];
    },

    companyBankAccounts() {
      return this.$store.getters["BANK_ACCOUNT/companyBankAccounts"];
    },

    objectDefaultCompanyBankId() {
      return this.$store.getters["BANK_ACCOUNT/objectDefaultCompanyBankId"];
    }
  },

  created() {
    this.selectedBankAccount = this.objectDefaultCompanyBankId || null;

    this.$store.dispatch("BANK_ACCOUNT/getCompanyBankAccounts");
  },

  methods: {
    async setDefaultThinkBankAccountForObject() {
      await this.$store.dispatch(
        "BANK_ACCOUNT/setDefaultThinkBankAccountForObject",
        {
          bankAccountId: this.selectedBankAccount,
          object: {
            id: this.objectId,
            type: this.objectType
          }
        }
      );

      if (
        this.bankAccountStatusRequest.value ===
        "success-setDefaultThinkBankAccountForObject"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật thành công"
          }
        });
      } else if (
        this.bankAccountStatusRequest.value ===
        "error-setDefaultThinkBankAccountForObject"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bankAccountStatusRequest.message.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>
