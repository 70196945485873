<template>
  <v-card flat>
    <form-bank-account-input
      v-if="isShowInputMode"
      :bank-account="bankAccount"
      :object-id="objectId"
      :object-type="objectType"
      @turnOffInputMode="isShowInputMode = $event"
    />

    <form-bank-account-view
      v-else
      :bank-account="bankAccount"
      :object-id="objectId"
      :object-type="objectType"
      @turnOnInputMode="isShowInputMode = $event"
    />
  </v-card>
</template>

<script>
import FormBankAccountInput from "@/core/components/form/FormBankAccount/components/FormBankAccountInput";
import FormBankAccountView from "@/core/components/form/FormBankAccount/components/FormBankAccountView";

export default {
  components: { FormBankAccountView, FormBankAccountInput },

  props: {
    bankAccount: {
      type: Object,
      required: true
    },
    inputMode: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: true
    },
    objectType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isShowInputMode: false
    };
  },

  created() {
    if (this.inputMode) {
      this.isShowInputMode = true;
    }
  }
};
</script>
