<template>
  <div class="pa-5">
    <div class="font-weight-bold mb-5">
      {{
        bankAccount.id ? "Sửa tài khoản giao dịch" : "Thêm tài khoản giao dịch"
      }}
    </div>

    <v-form ref="form">
      <div class="d-flex">
        <div style="width: 120px">
          <div class="pt-1">Ngân hàng</div>
        </div>
        <div class="flex-grow-1">
          <tp-autocomplete
            v-model="bankAccount.bank_id"
            :items="allBanks"
            item-text="name"
            item-value="id"
            placeholder="Chọn ngân hàng"
            validate="required"
          ></tp-autocomplete>
        </div>
      </div>

      <div class="d-flex">
        <div style="width: 120px">
          <div class="pt-1">Chủ tài khoản</div>
        </div>
        <div class="flex-grow-1">
          <tp-text-field
            v-model="bankAccount.name"
            placeholder="Tên chủ tài khoản"
            validate="required"
          ></tp-text-field>
        </div>
      </div>

      <div class="d-flex">
        <div style="width: 120px">
          <div class="pt-1">Số tài khoản</div>
        </div>
        <div class="flex-grow-1">
          <tp-text-field
            v-model="bankAccount.number"
            placeholder="Số tài khoản"
            validate="required"
          ></tp-text-field>
        </div>
      </div>

      <v-switch
        v-model="bankAccount.default"
        class="text-body-1 mt-0 pt-0"
        color="green"
        dense
        :false-value="false"
        hide-details
        label="Đặt làm tài khoản mặc định"
        :true-value="true"
      ></v-switch>
    </v-form>

    <v-btn
      v-if="!bankAccount.id"
      class="mt-5"
      color="primary"
      dark
      depressed
      @click="createObjectBankAccount"
    >
      Thêm tài khoản
    </v-btn>

    <div v-else class="d-flex mt-5">
      <v-btn
        class="mr-3"
        color="primary"
        dark
        depressed
        @click="updateObjectBankAccount"
      >
        Lưu
      </v-btn>
      <v-btn depressed @click="cancelUpdateObjectBankAccount">
        Hủy
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bankAccount: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: true
    },
    objectType: {
      type: Number,
      required: true
    }
  },

  computed: {
    allBanks() {
      return this.$store.getters["BANK_ACCOUNT/allBanks"];
    },

    bankAccountStatusRequest() {
      return this.$store.getters["BANK_ACCOUNT/statusRequest"];
    }
  },

  created() {
    if (this.allBanks.length === 0) {
      this.$store.dispatch("BANK_ACCOUNT/getAllBanks");
    }
  },

  methods: {
    async cancelUpdateObjectBankAccount() {
      await this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
        id: this.objectId,
        type: this.objectType
      });

      this.$emit("turnOffInputMode", false);
    },

    async createObjectBankAccount() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      const objectSender = JSON.parse(JSON.stringify(this.bankAccount));

      // Set object data
      objectSender.object.id = this.objectId;
      objectSender.object.type = this.objectType;

      // Create request
      await this.$store.dispatch(
        "BANK_ACCOUNT/createObjectBankAccount",
        objectSender
      );

      // Alert
      if (
        this.bankAccountStatusRequest.value ===
        "success-createObjectBankAccount"
      ) {
        // Reset bankAccount
        this.bankAccount.name = null;
        this.bankAccount.number = null;
        this.bankAccount.bank_id = null;
        this.bankAccount.default = false;

        await this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
          id: this.objectId,
          type: this.objectType
        });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm tài khoản thành công"
          }
        });
      } else if (
        this.bankAccountStatusRequest.value === "error-createObjectBankAccount"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bankAccountStatusRequest.message.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async updateObjectBankAccount() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      const objectSender = JSON.parse(JSON.stringify(this.bankAccount));

      console.log(objectSender);

      // Set object data
      objectSender.object = {
        id: this.objectId,
        type: this.objectType
      };

      // Create request
      await this.$store.dispatch(
        "BANK_ACCOUNT/updateObjectBankAccount",
        objectSender
      );

      // Alert
      if (
        this.bankAccountStatusRequest.value ===
        "success-updateObjectBankAccount"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật tài khoản thành công"
          }
        });
      } else if (
        this.bankAccountStatusRequest.value === "error-updateObjectBankAccount"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bankAccountStatusRequest.message.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>
