<template>
  <div
    class="text-body-1 px-4 py-3"
    :id="`formBankAccountView_${bankAccount.id}}`"
  >
    <div>
      <span>Ngân hàng:</span>
      <span class="font-weight-bold">
        {{ bankAccount.bank_code }} ({{ bankAccount.bank_name }})
      </span>
    </div>

    <div class="mt-1">
      <span>Chủ tài khoản:</span>
      <span class="font-weight-bold">
        {{ bankAccount.name }}
      </span>
    </div>

    <div class="mt-1">
      <span>Số tài khoản:</span>
      <span class="font-weight-bold">
        {{ bankAccount.number }}
      </span>
      <v-btn
        color="primary"
        icon
        small
        @click="
          copyToClipboard(
            bankAccount.number,
            `formBankAccountView_${bankAccount.id}}`
          )
        "
      >
        <v-icon size="18px">mdi-content-copy</v-icon>
      </v-btn>
    </div>

    <v-divider class="my-3"></v-divider>

    <div class="d-flex align-center">
      <v-switch
        v-model="bankAccount.default"
        class="text-body-1 mt-0 pt-0"
        color="green"
        dense
        :disabled="bankAccount.default"
        :false-value="false"
        hide-details
        label="Đặt làm tài khoản mặc định"
        :true-value="true"
        @change="setDefaultObjectBankAccount"
      ></v-switch>

      <v-divider class="ml-4 mr-1 mb-2" inset vertical></v-divider>

      <v-btn color="primary" text @click="turnOnInputMode">
        <v-icon left>mdi-pencil</v-icon>
        Chỉnh sửa
      </v-btn>

      <v-btn
        class="ml-1"
        color="red accent-2"
        text
        @click="deleteObjectBankAccount"
      >
        <v-icon left>mdi-minus-circle-outline</v-icon>
        Xóa
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bankAccount: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: true
    },
    objectType: {
      type: Number,
      required: true
    }
  },

  computed: {
    bankAccountStatusRequest() {
      return this.$store.getters["BANK_ACCOUNT/statusRequest"];
    }
  },

  methods: {
    deleteObjectBankAccount() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa tài khoản <strong>${this.bankAccount.number} - ${this.bankAccount.name}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "BANK_ACCOUNT/deleteObjectBankAccount",
                {
                  bankAccountId: this.bankAccount.id,
                  object: {
                    id: this.objectId,
                    type: this.objectType
                  }
                }
              );

              if (
                this.bankAccountStatusRequest.value ===
                "success-deleteObjectBankAccount"
              ) {
                await this.$store.dispatch(
                  "BANK_ACCOUNT/getObjectBankAccounts",
                  {
                    id: this.objectId,
                    type: this.objectType
                  }
                );

                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa tài khoản"
                  }
                });
              }
            }
          }
        }
      });
    },

    async setDefaultObjectBankAccount() {
      const objectSender = JSON.parse(JSON.stringify(this.bankAccount));

      // Set object data
      objectSender.object = {
        id: this.objectId,
        type: this.objectType
      };

      // Create request
      await this.$store.dispatch(
        "BANK_ACCOUNT/updateObjectBankAccount",
        objectSender
      );

      // Alert
      if (
        this.bankAccountStatusRequest.value ===
        "success-updateObjectBankAccount"
      ) {
        await this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
          id: this.objectId,
          type: this.objectType
        });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã đặt làm tài khoản mặc định"
          }
        });
      } else if (
        this.bankAccountStatusRequest.value === "error-updateObjectBankAccount"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bankAccountStatusRequest.message.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    turnOnInputMode() {
      this.$emit("turnOnInputMode", true);
    }
  }
};
</script>
